import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BACKEND_BASEURL;
const jewelleryAdmin = process.env.REACT_APP_JEWELLERY_ADMIN;
const productAdmin = process.env.REACT_APP_PRODUCT_ADMIN;
const storeAdmin = process.env.REACT_APP_STORE_ADMIN;
const accountsAdmin = process.env.REACT_APP_ACCOUNTS_ADMIN;
const coreUtilsAdmin = process.env.REACT_APP_COREUTILS_ADMIN;
const wishlistAdmin = process.env.REACT_APP_WISHLIST_ADMIN;
const customClientAdmin = process.env.REACT_APP_CUSTOMUTILS_ADMIN;
const wipClientAdmin = process.env.REACT_APP_WIP_ADMIN;
const designBankClientAdmin = process.env.REACT_APP_DESIGNBANK_ADMIN;
const customutilsApi = "customutils/api/admin/v1";
const productsApi = "products/api/admin/v1";
const accountsApi = "accounts/api/admin/v1";
const jewelleryApi = "jewellery-category/api/admin/v1";
const socialApi = "social-media/api/admin/v1";
const bestSellerApi = "best-seller/api/admin/v1";
const synergicsAdminApi = "synergics/api/admin/v1";
// let ip_data;

// axios
//   .get("https://api.ipify.org/?format=json", {
//     headers: {
//       "Content-Type": "application/json",
//     },
//   })
//   .then((data) => console.log(data));

// const NewAxios = axios.create({
//   baseURL: process.env.NEXT_PUBLIC_REST_API_ENDPOINT,
//   timeout: 5000000,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// NewAxios.get("https://api.ipify.org/?format=json").then(
//   (data) => (ip_data = data?.data?.ip)
//   // console.log(data.data.ip)
// );

const restApi = createApi({
  reducerPath: "restApi",

  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const salesRepToken = getState()?.auth?.userData?.accessToken;
      const customerToken = getState()?.customerLogin?.accessToken;
      const ipAddress = getState()?.popup.ipAddress.address;
      if (salesRepToken) {
        headers.set("authorization", `Bearer ${salesRepToken}`);
      }

      if (customerToken) {
        headers.set("x-customer-token", `${customerToken}`);
      }

      // NewAxios.get("https://api.ipify.org/?format=json").then(
      //   (data) => (ip_data = data?.data?.ip)
      //   // console.log(data.data.ip)
      // );

      // console.log(ip_data);
      if (Boolean(ipAddress)) {
        console.log("data ===>", ipAddress);
        headers.set("x-ip-address", `${ipAddress}`);
      }

      return headers;
    },
  }),

  tagTypes: [
    "CustomerProfile",
    "updateCustomerProfile",
    "catalogueProducts",
    "lazyAllProducts",
    "allProducts",
    "wishlistList",
    "logout",
    "refresh",
    "filters",

    // bulk upload
    "bulkupload",
    "goldprice",
    "userData",
    "productType",
    "category",
    "editedUserData",
  ],

  endpoints: (builder) => ({
    // ** Catalogue Filters
    // ** Category filter
    getCatalogueCategoryFilters: builder.query({
      query: (data) =>
        data?.search
          ? `${jewelleryAdmin}/jewellery-category/?limit=${data?.limit}&offset=${data?.offset}&product_type=${data?.product_type}&search=${data?.search}`
          : `${jewelleryAdmin}/jewellery-category/?limit=${data?.limit}&offset=${data?.offset}&product_type=${data?.product_type}`,
      providesTags: ["filters", "category"],
    }),

    //CustomerLogin
    customerLogin: builder.mutation({
      query: (data) => ({
        url: `${accountsAdmin}/customer-login/`,
        method: "POST",
        body: data,
      }),
    }),

    //customer login Id's and Password
    getCustomerLoginDetails: builder.query({
      query: (data) =>
        `${accountsAdmin}/get-all-customers/?is_customer=true&search=${data?.val}`,
    }),

    //Customer visit store
    getCustomerVisitStore: builder.query({
      query: (data) =>
        `${accountsAdmin}/customer-visited-history/?limit=${data?.limit}&offset=${data?.offset}`,
      providesTags: "logout",
    }),

    //Countries
    getCountriesList: builder.query({
      query: () => `${coreUtilsAdmin}/country-admin/`,
    }),

    //All States
    getAllStateDetails: builder.query({
      query: () => `${coreUtilsAdmin}/state-admin/`,
      providesTags: ["refresh"],
    }),

    //All Cities
    getAllCityDetails: builder.query({
      query: () => `${coreUtilsAdmin}/city-admin/`,
    }),

    getFilterCityData: builder.query({
      query: (data) =>
        `${coreUtilsAdmin}/city-admin/?state_city=${data?.state_city}`,
      providesTags: ["refresh"],
    }),

    getCityDataRefresh: builder.query({
      query: (data) =>
        `${coreUtilsAdmin}/city-admin/?state_city=${data?.state_city}`,
      providesTags: ["refresh"],
    }),

    //CustomerRegistration
    customerRegistration: builder.mutation({
      query: (data) => ({
        url: `${accountsAdmin}/customer-registration/`,
        method: "POST",
        body: data,
      }),
    }),

    //Customer visit store
    getCustomerVisitStore: builder.query({
      query: (data) =>
        `${accountsAdmin}/customer-visited-history/?limit=${data?.limit}&offset=${data?.offset}`,
    }),

    //Sales Person Details
    getSalesProfileDetails: builder.query({
      query: (data) => `${accountsAdmin}/sales-person-details/`,
      providesTags: ["CustomerProfile"],
    }),

    //Customer Details
    getCustomerDetails: builder.query({
      query: (data) => `${accountsAdmin}/customer-details/`,
      providesTags: ["CustomerProfile", "updateCustomerProfile"],
    }),

    //customer profile update
    addUpdateCustomer: builder.mutation({
      query: (data) => ({
        url: `${accountsAdmin}/update-customer/${data.slug}/`,
        method: "PUT",
        body: data.data,
      }),
      invalidatesTags: ["updateCustomerProfile"],
    }),

    //customer profile update
    getCustomerProfileUpdate: builder.mutation({
      query: (data) => ({
        url: `${accountsAdmin}/update-customer/${data.slug}/`,
        method: "PUT",
        body: data.data,
      }),
      invalidatesTags: ["updateCustomerProfile"],
    }),

    //Catalogue Products Details
    getCatalogueProductDetails: builder.query({
      query: (data) => `${productAdmin}/product-details/${data?.slug}/`,
      providesTags: ["catalogueProducts"],
    }),

    getBestSellerProductDetails: builder.query({
      query: (data) => `${bestSellerApi}/best-seller-products/${data?.slug}/`,
      providesTags: ["best-seller-product-details-sales"],
    }),
    getSocialMediaProductDetails: builder.query({
      query: (data) => `${socialApi}/social-products/${data?.slug}/`,
      providesTags: ["social-media-product-details-sales"],
    }),

    // Select Jewel Extimateprice
    getExtimatePriceDetails: builder.query({
      query: (data) =>
        `${productAdmin}/product-estimate-details/${data?.slug}/`,
      providesTags: ["catalogueProducts"],
    }),

    // ** Sub Category Filter
    getCatalogueSubCategoryFilters: builder.query({
      query: (data) =>
        data.search
          ? `${jewelleryAdmin}/jewellery-sub-category/?limit=${data?.limit}&offset=${data?.offset}&product_type=${data?.product_type}&category=${data?.category}&search=${data?.search}`
          : `${jewelleryAdmin}/jewellery-sub-category/?limit=${data?.limit}&offset=${data?.offset}&product_type=${data?.product_type}&category=${data?.category}`,
      providesTags: ["filters"],
    }),

    getCatalogueSubCategoryFiltersSales: builder.query({
      query: (data) =>
        `${jewelleryAdmin}/jewellery-sub-category/?limit=${data?.limit}&offset=${data?.offset}&product_type=${data?.product_type}&category=${data?.category}&search=${data?.search}`,
      providesTags: ["filters"],
    }),

    getSocialCatalogueSubCategoryFiltersSales: builder.query({
      query: (data) => {
        const { product_type = "", category = "", search = "" } = data;
        return `${socialApi}/sub-categories/?limit=${data?.limit}&offset=${data?.offset}&product_type=${product_type}&category=${category}&search=${search}`;
      },
      providesTags: ["filters-social-sub"],
    }),
    getSocialCatalogueSubSubCategoryFiltersSales: builder.query({
      query: (data) =>
        `${socialApi}/sub-sub-categories/?limit=${data?.limit}&offset=${data?.offset}&product_type=${data?.product_type}&category=${data?.category}&search=${data?.search}`,
      providesTags: ["filters-social-sub-sub"],
    }),
    getBestSellerCatalogueSubCategoryFiltersSales: builder.query({
      query: (data) => {
        const { search = "" } = data;
        return `${bestSellerApi}/sub-categories/?limit=${data?.limit}&offset=${data?.offset}&product_type=${data?.product_type}&category=${data?.category}&search=${search}`;
      },

      providesTags: ["filters-best-seller-sub"],
    }),

    getBestSellerCatalogueSubSubCategoryFiltersSales: builder.query({
      query: (data) =>
        `${bestSellerApi}/sub-sub-categories/?limit=${data?.limit}&offset=${data?.offset}&product_type=${data?.product_type}&category=${data?.category}&search=${data?.search}`,
      providesTags: ["filters-best-seller-sub-sub"],
    }),
    getWipCatalogueSubCategoryFiltersSales: builder.query({
      query: (data) => {
        const { product_type = "", category = "" } = data;
        return `${wipClientAdmin}/sub-categories/?limit=${data?.limit}&offset=${data?.offset}&product_type=${product_type}&category=${category}&search=${data?.search}`;
      },
      providesTags: ["filters-wip-sub"],
    }),

    getDesignBankCatalogueSubCategoryFiltersSales: builder.query({
      query: (data) => {
        const { product_type = "", category = "", search = "" } = data;
        return `${designBankClientAdmin}/sub-categories/?limit=${data?.limit}&offset=${data?.offset}&product_type=${product_type}&category=${category}&search=${search}`;
      },
      providesTags: ["filters-design-bank-sub"],
    }),

    getDesignSources: builder.query({
      query: (data) => {
        const { search = "" } = data;
        return `${designBankClientAdmin}/design-source/?limit=${data?.limit}&offset=${data?.offset}&search=${search}`;
      },
    }),

    getDesignNames: builder.query({
      query: (data) => {
        const { search = "" } = data;
        return `${designBankClientAdmin}/designer-name/?limit=${data?.limit}&offset=${data?.offset}&search=${search}`;
      },
    }),

    getWipStatusReportFiltersSales: builder.query({
      query: (data) =>
        `${wipClientAdmin}/status-report/?limit=${data?.limit}&offset=${data?.offset}`,
      providesTags: ["filters-wip-status-report"],
    }),
    getWipStatusDueFiltersSales: builder.query({
      query: (data) =>
        `${wipClientAdmin}/status-due/?limit=${data?.limit}&offset=${data?.offset}`,
      providesTags: ["filters-wip-status-due"],
    }),
    getWipSupplierFiltersSales: builder.query({
      query: (data) =>
        `${wipClientAdmin}/supplier/?limit=${data?.limit}&offset=${data?.offset}`,
      providesTags: ["filters-wip-supplier"],
    }),
    getWipKarigarLocationFiltersSales: builder.query({
      query: (data) =>
        `${wipClientAdmin}/karigar-locations/?limit=${data?.limit}&offset=${data?.offset}`,
      providesTags: ["filters-wip-karigar-locations"],
    }),

    getWipIndentLocationsFiltersSales: builder.query({
      query: (data) =>
        `${wipClientAdmin}/indent-locations/?limit=${data?.limit}&offset=${data?.offset}`,
      providesTags: ["filters-wip-indent-locations"],
    }),

    getDesignBankClassificationData: builder.query({
      query: (data) =>
        `${designBankClientAdmin}/classifications/?limit=${data?.limit}&offset=${data?.offset}`,
      providesTags: ["filters-design-bank-classification"],
    }),

    getDesignBankPolishData: builder.query({
      query: (data) =>
        `${designBankClientAdmin}/polish/?limit=${data?.limit}&offset=${data?.offset}`,
      providesTags: ["filters-design-bank-polish"],
    }),
    getDesignBankPurityData: builder.query({
      query: (data) =>
        `${designBankClientAdmin}/purity/?limit=${data?.limit}&offset=${data?.offset}`,
      providesTags: ["filters-design-bank-purity"],
    }),
    getDesignBankCollectionData: builder.query({
      query: (data) =>
        `${designBankClientAdmin}/collection/?limit=${data?.limit}&offset=${data?.offset}`,
      providesTags: ["filters-design-bank-collection"],
    }),
    getDesignBankOccationData: builder.query({
      query: (data) =>
        `${designBankClientAdmin}/occation/?limit=${data?.limit}&offset=${data?.offset}`,
      providesTags: ["filters-design-bank-occation"],
    }),
    getWipCatalogueSubSubCategoryFiltersSales: builder.query({
      query: (data) => {
        const { product_type = "", category = "", search = "" } = data;
        return `${wipClientAdmin}/sub-sub-categories/?limit=${data?.limit}&offset=${data?.offset}&product_type=${product_type}&category=${category}&search=${search}`;
      },
      providesTags: ["filters-wip-sub-sub"],
    }),
    getDesignBankCatalogueSubSubCategoryFiltersSales: builder.query({
      query: (data) => {
        const { product_type = "", category = "", search = "" } = data;
        return `${designBankClientAdmin}/sub-sub-categories/?limit=${data?.limit}&offset=${data?.offset}&product_type=${product_type}&category=${category}&search=${search}`;
      },
      providesTags: ["filters-design-bank-sub-sub"],
    }),

    // ** Product Classification Filter
    getProductClassificationFilters: builder.query({
      query: (data) =>
        data?.search
          ? `${productAdmin}/product-classification/?limit=${data?.limit}&offset=${data?.offset}&search=${data?.search}`
          : `${productAdmin}/product-classification/?limit=${data?.limit}&offset=${data?.offset}`,
      providesTags: ["filters"],
    }),

    getAllProductSizes: builder.query({
      query: (data) => {
        const { limit = 6, offset = 0, search = "" } = data;
        return `${productAdmin}/product-size/?limit=${limit}&offset=${offset}&search=${search}`;
      },

      providesTags: ["filters"],
    }),

    getSocialClassificationFilters: builder.query({
      query: (data) =>
        data?.search
          ? `${socialApi}/classification/?limit=${data?.limit}&offset=${data?.offset}&search=${data?.search}`
          : `${socialApi}/classification/?limit=${data?.limit}&offset=${data?.offset}`,
      providesTags: ["filters"],
    }),
    getBestSellerClassificationFilters: builder.query({
      query: (data) =>
        data?.search
          ? `${bestSellerApi}/classification/?limit=${data?.limit}&offset=${data?.offset}&search=${data?.search}`
          : `${bestSellerApi}/classification/?limit=${data?.limit}&offset=${data?.offset}`,
      providesTags: ["filters"],
    }),
    getWipClassificationFilters: builder.query({
      query: (data) =>
        data?.search
          ? `${wipClientAdmin}/classification/?limit=${data?.limit}&offset=${data?.offset}&search=${data?.search}`
          : `${wipClientAdmin}/classification/?limit=${data?.limit}&offset=${data?.offset}`,
      providesTags: ["filters"],
    }),

    // ** Product Type Filter
    getCatalogueProductTypeFilters: builder.query({
      query: (data) =>
        data?.category?.length
          ? data?.search
            ? `${jewelleryAdmin}/jewellery-product-type/?limit=${data?.limit}&offset=${data?.offset}&JewelleryCategoryModel_product_type=${data?.category}&search=${data?.search}`
            : `${jewelleryAdmin}/jewellery-product-type/?limit=${data?.limit}&offset=${data?.offset}&JewelleryCategoryModel_product_type=${data?.category}`
          : data?.search
          ? `${jewelleryAdmin}/jewellery-product-type/?limit=${data?.limit}&offset=${data?.offset}&search=${data?.search}`
          : `${jewelleryAdmin}/jewellery-product-type/?limit=${data?.limit}&offset=${data?.offset}`,

      providesTags: ["filters"],
    }),

    // ** Metal Color Filter
    getMetalColorFilters: builder.query({
      query: (data) => `${productAdmin}/product-colors/`,
      providesTags: ["filters"],
    }),

    // ** Location filter
    getLocationFilters: builder.query({
      query: (data) => {
        const { search = "" } = data;
        return `${productAdmin}/product-locations/?limit=${data?.limit}&offset=${data?.offset}&search=${search}`;
      },
      providesTags: ["filters"],
    }),

    // ** Location filter
    updateProductLocations: builder.mutation({
      query: (data) => ({
        url: `${productAdmin}/product-locations/${data?.slug}/`,
        method: "PUT",
        body: data?.data,
      }),
      invalidatesTags: ["filters"],
    }),

    // ** Selected-Size Filter
    getSelectedSizeFilters: builder.query({
      query: (data) => `${productAdmin}/product-sizes/`,
      providesTags: ["filters"],
    }),

    // ** Digital Catalogue
    // ** Trending Products
    getTrendingProducts: builder.query({
      query: (data) =>
        `${productAdmin}/trending-product/?limit=${data?.limit}&offset=${data?.offset}&category=${data?.category}&product_type=${data?.productType}&sub_category=${data?.subCategory}&colour=${data?.color}&location=${data?.store}&gender=${data?.gender}&min_price=${data?.minPrice}&max_price=${data?.maxPrice}&size=${data?.size}&sub_sub_category=${data?.sub_sub_category}&classification=${data?.classification}`,
      providesTags: ["allProducts"],
    }),

    // ** Best Seller Products
    getBestSellerProductsSales: builder.query({
      query: (data) =>
        `${bestSellerApi}/best-seller-products/?limit=${data?.limit}&offset=${data?.offset}&min_diamond_weight=${data.min_diamond_weight}&max_diamond_weight=${data.max_diamond_weight}&category=${data?.category}&product_type=${data?.productType}&sub_category=${data?.subCategory}&colour=${data?.color}&location=${data?.store}&gender=${data?.gender}&min_price=${data?.minPrice}&max_price=${data?.maxPrice}&size=${data?.size}&sub_sub_category=${data?.sub_sub_category}&classification=${data?.classification}&min_net_weight=${data?.minWeight}&max_net_weight=${data?.maxWeight}&ordering=${data.ordering}&retail=${data.retail}&search=${data.search}`,
      providesTags: ["all-Products-best-seller-sales"],
    }),

    getSocialMediaProductsSales: builder.query({
      query: (data) =>
        `${socialApi}/social-products/?limit=${data?.limit}&offset=${data?.offset}&category=${data?.category}&product_type=${data?.productType}&sub_category=${data?.subCategory}&colour=${data?.color}&location=${data?.store}&gender=${data?.gender}&min_price=${data?.minPrice}&max_price=${data?.maxPrice}&min_net_weight=${data?.minWeight}&max_net_weight=${data?.maxWeight}&min_diamond_weight=${data?.min_diamond_weight}&max_diamond_weight=${data.max_diamond_weight}&size=${data?.size}&sub_sub_category=${data?.sub_sub_category}&classification=${data?.classification}&vendor=${data?.vendor}&ordering=${data.ordering}&search=${data.search}`,
      providesTags: ["all-Products-social-media-sales"],
    }),

    // ** Best Seller Products
    getCatalogueProducts: builder.query({
      query: (data) => {
        const {
          latitude = "",
          longitude = "",
          classification = "",
          search = "",
          sub_sub_category = "",
          size = "",
          maxWeight = "",
          minWeight = "",
          maxPrice = "",
          minPrice = "",
          min_diamond_weight = "",
          max_diamond_weight = "",
          ordering = "",
          department = "",
        } = data;
        return `${productAdmin}/catalogue-products/?limit=${data?.limit}&offset=${data?.offset}&min_diamond_weight=${min_diamond_weight}&max_diamond_weight=${max_diamond_weight}&category=${data?.category}&product_type=${data?.productType}&sub_category=${data?.subCategory}&colour=${data?.color}&location=${data?.store}&gender=${data?.gender}&min_price=${minPrice}&max_price=${maxPrice}&min_net_weight=${minWeight}&max_net_weight=${maxWeight}&size=${size}&sub_sub_category=${sub_sub_category}&search=${search}&classification=${classification}&longitude=${longitude}&latitude=${latitude}&ordering=${ordering}&department=${department}`;
      },
      providesTags: ["allProducts"],
    }),

    // ** Best Seller Products
    getProductsCatalogue: builder.query({
      query: (data) =>
        `${productAdmin}/products-catalogue/?limit=${data?.limit}&offset=${data?.offset}&category=${data?.category}&product_type=${data?.productType}&sub_category=${data?.subCategory}&colour=${data?.color}&location=${data?.store}&gender=${data?.gender}&min_price=${data?.minPrice}&max_price=${data?.maxPrice}&min_net_weight=${data?.minWeight}&max_net_weight=${data?.maxWeight}&size=${data?.size}&sub_sub_category=${data?.sub_sub_category}&search=${data?.search}&classification=${data?.classification}&ordering=${data?.ordering}`,
      providesTags: ["allProducts"],
    }),

    getBulkCatalogueAdmin: builder.query({
      query: (data) => `${productAdmin}/products-catalogue/${data.params}`,
      providesTags: ["all-catalogue-products-admin"],
    }),

    getBulkCatalogueProducts: builder.query({
      query: (data) => `${productAdmin}/catalogue-products/${data.params}`,
      providesTags: ["allProducts"],
    }),

    getBulkBestSellerProducts: builder.query({
      query: (data) => `${bestSellerApi}/best-seller-products/${data.params}`,
      providesTags: ["allProducts-best-seller"],
    }),
    getBulkWipProducts: builder.query({
      query: (data) => `${wipClientAdmin}/wip-products/${data.params}`,
      providesTags: ["allProducts-wip"],
    }),
    getBulkDesignBankProducts: builder.query({
      query: (data) =>
        `${designBankClientAdmin}/design-bank-products/${data.params}`,
      providesTags: ["allProducts-design-bank"],
    }),

    getBestSellerEstimatePriceDetails: builder.query({
      query: (data) => `${bestSellerApi}/best-seller-products/${data?.slug}/`,
      providesTags: [`best-seller-estimate-details`],
    }),
    getDesignBankEstimatePriceDetails: builder.query({
      query: (data) =>
        `${designBankClientAdmin}/design-bank-products/${data?.slug}/`,
      providesTags: [`design-bank-estimate-details`],
    }),
    getWipEstimatePriceDetails: builder.query({
      query: (data) => `${wipClientAdmin}/wip-products/${data?.slug}/`,
      providesTags: [`wip-estimate-details`],
    }),
    getBulkSocialProducts: builder.query({
      query: (data) => `${socialApi}/social-products/${data.params}`,
      providesTags: ["allProducts-social"],
    }),
    getBulkSocialProductsTotalCalculation: builder.query({
      query: (data) => `${socialApi}/total-calculation/${data.params}`,
      providesTags: ["total-calculation-social"],
    }),
    getBulkWipProductsTotalCalculation: builder.query({
      query: (data) => `${wipClientAdmin}/total-calculation/${data.params}`,
      providesTags: ["total-calculation-wip"],
    }),
    getBulkInventoryProductsTotalCalculation: builder.query({
      query: (data) => `${productsApi}/total-calculation/${data.params}`,
      providesTags: ["total-calculation-inventory"],
    }),
    getBulkBestSellerProductsTotalCalculation: builder.query({
      query: (data) => `${bestSellerApi}/total-calculation/${data.params}`,
      providesTags: ["total-calculation-best-seller"],
    }),
    getBulkDesignBankProductsTotalCalculation: builder.query({
      query: (data) =>
        `${designBankClientAdmin}/total-calculation/${data.params}`,
      providesTags: ["total-calculation-design-bank"],
    }),

    getSocialEstimatePriceDetails: builder.query({
      query: (data) => `${socialApi}/social-products/${data?.slug}/`,
      providesTags: [`social-estimate-details`],
    }),

    // ** Current Trends Products
    getCurrentTrendsProducts: builder.query({
      query: (data) =>
        `${productAdmin}/current-trending-product/?limit=${data?.limit}&offset=${data?.offset}&category=${data?.category}&product_type=${data?.productType}&sub_category=${data?.subCategory}&colour=${data?.color}&location=${data?.store}&gender=${data?.gender}&min_price=${data?.minPrice}&max_price=${data?.maxPrice}&size=${data?.size}&sub_sub_category=${data?.sub_sub_category}&classification=${data?.classification}`,
      providesTags: ["allProducts"],
    }),

    // ** Current Trends Products
    getTimelessProducts: builder.query({
      query: (data) =>
        `${productAdmin}/timeless-jewellery/?limit=${data?.limit}&offset=${data?.offset}&category=${data?.category}&product_type=${data?.productType}&sub_category=${data?.subCategory}&colour=${data?.color}&location=${data?.store}&gender=${data?.gender}&min_price=${data?.minPrice}&max_price=${data?.maxPrice}&size=${data?.size}&sub_sub_category=${data?.sub_sub_category}&classification=${data?.classification}`,
      providesTags: ["allProducts"],
    }),

    // ** Whishlist
    // ** get wishlist dates of user
    getUserWishlistDates: builder.query({
      query: (data) =>
        `${wishlistAdmin}/datewise-wishlist/?limit=${data?.limit}&offset=${data.offset}`,
      providesTags: ["allProducts"],
    }),

    getUserWishlistDatesInventory: builder.query({
      query: (data) =>
        `${wishlistAdmin}/datewise-inventory/?limit=${data?.limit}&offset=${data.offset}`,
      providesTags: ["wishlist-dates-inventory"],
    }),

    getUserWishlistDatesSocialMedia: builder.query({
      query: (data) =>
        `${wishlistAdmin}/datewise-social-media/?limit=${data?.limit}&offset=${data.offset}`,
      providesTags: ["wishlist-dates-social-media"],
    }),

    getUserWishlistDatesBestSeller: builder.query({
      query: (data) =>
        `${wishlistAdmin}/datewise-best-seller/?limit=${data?.limit}&offset=${data.offset}`,
      providesTags: ["wishlist-dates-inventory-best-seller"],
    }),

    getFilesUploadedDate: builder.query({
      query: (data) => `${customutilsApi}/file-uploaded-date/`,
      providesTags: ["file-uploaded-date"],
    }),

    // ** get wishlist items by date
    getWishlistByDate: builder.query({
      query: (data) =>
        `${wishlistAdmin}/customer-wishlist/?date=${data.date}&limit=${data?.limit}&offset=${data.offset}`,
      providesTags: ["allProducts"],
    }),

    getWishlistByDateInventory: builder.query({
      query: (data) =>
        `${wishlistAdmin}/inventory-products/?date=${data.date}&limit=${data?.limit}&offset=${data.offset}`,
      providesTags: ["inventory-wishlist"],
    }),

    getInventoryRelatedProducts: builder.query({
      query: (data) =>
        `${productsApi}/similar-products/${data.slug}/?limit=${data?.limit}&offset=${data.offset}`,
      providesTags: ["inventory-related-products"],
    }),

    getWishlistByDateBestSeller: builder.query({
      query: (data) =>
        `${wishlistAdmin}/best-seller-products/?date=${data.date}&limit=${data?.limit}&offset=${data.offset}`,
      providesTags: ["best-seller-wishlist"],
    }),

    getWishlistByDateSocialMedia: builder.query({
      query: (data) =>
        `${wishlistAdmin}/social-media-products/?date=${data.date}&limit=${data?.limit}&offset=${data.offset}`,
      providesTags: ["social-media-wishlist"],
    }),

    //Add to WishList
    AddToWishList: builder.mutation({
      query: (data) => ({
        url: `${wishlistAdmin}/product-to-wishlist/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        "catalogueProducts",
        "lazyAllProducts",
        "allProducts",
        "wishlistList",
      ],
    }),

    AddToIpAddress: builder.mutation({
      query: (data) => ({
        url: `${customutilsApi}/ip-address/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["all-ip-address"],
    }),

    addSynergicsUserCred: builder.mutation({
      query: (data) => ({
        url: `${synergicsAdminApi}/user-cred/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["all-synergics-user-cred"],
    }),

    addSynergicsFinanceFile: builder.mutation({
      query: (data) => ({
        url: `${synergicsAdminApi}/finance-file/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["all-synergics-finance-file"],
    }),

    AddToWishListWithTypes: builder.mutation({
      query: (data) => ({
        url: `${wishlistAdmin}/product-codes-to-wishlist/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        "catalogueProducts",
        "lazyAllProducts",
        "allProducts",
        "wishlistList",
        "all-Products-best-seller-sales",
        "all-Products-social-media-sales",
        "best-seller-product-details-sales",
        "social-media-product-details-sales",
        "inventory-wishlist",
        "best-seller-wishlist",
        "social-media-wishlist",
      ],
    }),

    //Add to WishList
    AddToWishScanProduct: builder.mutation({
      query: (data) => ({
        url: `${wishlistAdmin}/qrcode-product-to-wishlist/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        "catalogueProducts",
        "lazyAllProducts",
        "allProducts",
        "wishlistList",
      ],
    }),

    //Scan to ProductDetailsPage
    addScanToProductDetails: builder.mutation({
      query: (data) => ({
        url: `${productAdmin}/get-qrcode-product/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        // "catalogueProducts",
        // "lazyAllProducts",
        // "allProducts",
        "wishlistList",
        "allProducts",
      ],
    }),

    //post  select Jewel EstimatePrice
    handleConditionEstimatePriceDetails: builder.mutation({
      query: (data) => ({
        url: `${customClientAdmin}/estimate-pdf/`,
        method: "POST",
        body: data,
      }),
    }),

    handleSocialConditionEstimatePriceDetails: builder.mutation({
      query: (data) => ({
        url: `${socialApi}/estimate-pdf/`,
        method: "POST",
        body: data,
      }),
    }),

    handleBestSellerConditionEstimatePriceDetails: builder.mutation({
      query: (data) => ({
        url: `${bestSellerApi}/estimate-pdf/`,
        method: "POST",
        body: data,
      }),
    }),
    //post  select Jewel EstimatePrice
    handleEstimateStatusUpdate: builder.mutation({
      query: (data) => ({
        url: `${customClientAdmin}/estimate-status-update-pdf/${data?.slug}/`,
        method: "PUT",
        body: data,
      }),
    }),

    handleWhatsAppShare: builder.query({
      query: (data) => ({
        url: `${customClientAdmin}/product-details-pdf/${data.slug}/`,
      }),
    }),

    handleWishlistShare: builder.query({
      query: (data) => ({
        url: `${customClientAdmin}/customer-wishlist/${data?.slug}/?date=${
          data?.date ? data?.date : ""
        }`,
      }),
      providesTags: ["wishlistList"],
    }),

    //logout
    handleLogout: builder.mutation({
      query: (data) => ({
        url: `${accountsAdmin}/customer-checkout/${data?.slug}`,
        method: "GET",
      }),
      providesTags: ["logout"],
    }),

    //catalogue sub-sub-category
    getCatalogueSubSubCategoryType: builder.query({
      query: (data) =>
        `${jewelleryAdmin}/jewellery-sub-sub-category/?limit=${
          data?.limit
        }&offset=${data?.offset}&product_type=${
          data?.product_type ? product_type : ""
        }&category=${data?.category ? category : ""}&sub_category=${
          data?.sub_category ? sub_category : ""
        }`,
      providesTags: ["filters"],
    }),

    // updateChangeSalesPassword: builder.mutation({
    //   query: (data) => ({
    //     url: `${accountsAdmin}/change-password/${data.slug}/`,
    //     method: "POST",
    //     body: data.data,
    //   }),
    // }),
    changeSalesPassword: builder.mutation({
      query: (data) => ({
        url: `${accountsApi}/change-password/${data?.slug}/`,
        method: "PUT",
        body: data.data,
      }),
    }),
    //......................................Bulk Upload Starts.............................................//

    //get bulk upload inventory files Data
    getAllBulkuploadFiles: builder.query({
      query: (data) =>
        `${customutilsApi}/all-bulkupload-files/?date=${data?.date}&limit=${data.limit}&offset=${data.offset}&type=${data?.type}`,
      providesTags: ["bulkupload"],
    }),

    // Get Ip address
    getAllIpAddress: builder.query({
      query: (data) =>
        `${customutilsApi}/ip-address/?date=${data?.date}&limit=${data.limit}&offset=${data.offset}&type=${data?.type}`,
      providesTags: ["all-ip-address"],
    }),

    getAllSynergicsUserCred: builder.query({
      query: (data) => `${synergicsAdminApi}/user-cred/`,
      providesTags: ["all-synergics-user-cred"],
    }),
    getAllSynergicsFinanceFile: builder.query({
      query: (data) =>
        `${synergicsAdminApi}/finance-file/?date=${data?.date}&limit=${data.limit}&offset=${data.offset}&type=${data?.type}`,
      providesTags: ["all-synergics-finance-file"],
    }),

    // upload file
    handleUploadFile: builder.mutation({
      query: (data) => ({
        url: `${productsApi}/product-bulkupload/`,
        method: "POST",
        body: data.data,
      }),
      invalidatesTags: ["bulkupload"],
    }),

    //get user Data
    getUsersData: builder.query({
      query: (data) =>
        data?.is_customer
          ? `${accountsApi}/all-users/?limit=${data.limit}&offset=${data.offset}&search=${data?.search}&is_customer=${data?.is_customer}`
          : `${accountsApi}/all-users/?limit=${data.limit}&offset=${
              data.offset
            }&search=${data?.search}&${
              data?.role && `${data?.role}=true`
            }&employee=${data?.employee}`,

      providesTags: ["userData", "editedUserData"],
    }),

    //get user Details
    getUserDetail: builder.query({
      query: (data) => `${accountsApi}/user-detail/${data.slug}/`,
      providesTags: ["editedUserData"],
    }),

    // update Gold Price
    handleUpdateGoldPrice: builder.mutation({
      query: (data) => ({
        url: `${customutilsApi}/metalprice-update/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["goldprice"],
    }),

    //get metal price data
    getMetalPriceData: builder.query({
      query: (data) => `${customutilsApi}/get-metalprice/`,
      providesTags: ["goldprice"],
    }),

    // Add user
    handleAddUser: builder.mutation({
      query: (data) => ({
        url: `${accountsApi}/user-registration/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["userData"],
    }),

    getJewelleryCategoryData: builder.query({
      query: (data) => ({
        url: `${jewelleryApi}/jewellery-category/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
      }),
      providesTags: ["category"],
    }),

    getSocialCategoryData: builder.query({
      query: (data) => {
        const { search = "", product_type = "" } = data;
        return `${socialApi}/categories/?limit=${data.limit}&offset=${data.offset}&search=${search}&product_type=${product_type}`;
      },
      providesTags: ["category-social"],
    }),

    getBestSellerCategoryData: builder.query({
      query: (data) => {
        const { search = "" } = data;
        return `${bestSellerApi}/categories/?limit=${data.limit}&offset=${data.offset}&search=${search}`;
      },
      providesTags: ["category-best-seller"],
    }),
    getWipCategoryData: builder.query({
      query: (data) => ({
        url: `${wipClientAdmin}/categories/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
      }),
      providesTags: ["category-wip"],
    }),
    getDesignBankCategoryData: builder.query({
      query: (data) => ({
        url: `${designBankClientAdmin}/categories/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
      }),
      providesTags: ["category-design-bank"],
    }),
    getWipSubCategoryData: builder.query({
      query: (data) => ({
        url: `${wipClientAdmin}/sub-categories/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
      }),
      providesTags: ["sub-category-wip"],
    }),

    getDesignBankSubCategoryData: builder.query({
      query: (data) => ({
        url: `${designBankClientAdmin}/sub-categories/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
      }),
      providesTags: ["sub-category-design-bank"],
    }),
    getWipSubSubCategoryData: builder.query({
      query: (data) => ({
        url: `${wipClientAdmin}/sub-sub-categories/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
      }),
      providesTags: ["sub-sub-category-wip"],
    }),
    getDesignBankSubSubCategoryData: builder.query({
      query: (data) => ({
        url: `${designBankClientAdmin}/sub-sub-categories/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
      }),
      providesTags: ["sub-sub-category-design-bank"],
    }),
    updateJewelCategory: builder.mutation({
      query: (data) => ({
        url: `${jewelleryApi}/jewellery-category/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["category"],
    }),

    updateSocialCategory: builder.mutation({
      query: (data) => ({
        url: `${socialApi}/categories/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["category-social"],
    }),
    updateBestSellerCategory: builder.mutation({
      query: (data) => ({
        url: `${bestSellerApi}/categories/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["category-best-seller"],
    }),
    updateDesignBankCategory: builder.mutation({
      query: (data) => ({
        url: `${designBankClientAdmin}/categories/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["category-design-bank"],
    }),

    updateWipCategory: builder.mutation({
      query: (data) => ({
        url: `${wipClientAdmin}/categories/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["category-wip"],
    }),

    updateWipSubCategory: builder.mutation({
      query: (data) => ({
        url: `${wipClientAdmin}/sub-categories/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["sub-category-wip"],
    }),
    updateDesignBankSubCategory: builder.mutation({
      query: (data) => ({
        url: `${designBankClientAdmin}/sub-categories/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["sub-category-design-bank"],
    }),

    updateDesignBankSubSubCategory: builder.mutation({
      query: (data) => ({
        url: `${designBankClientAdmin}/sub-sub-categories/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["sub-sub-category-design-bank"],
    }),
    updateWipSubSubCategory: builder.mutation({
      query: (data) => ({
        url: `${wipClientAdmin}/sub-sub-categories/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["sub-sub-category-wip"],
    }),

    getJewelleryProductTypeData: builder.query({
      query: (data) =>
        data?.category
          ? `${jewelleryApi}/jewellery-product-type/?limit=${data.limit}&offset=${data.offset}&search=${data?.search}&JewelleryCategoryModel_product_type=${data?.category}`
          : `${jewelleryApi}/jewellery-product-type/?limit=${data.limit}&offset=${data.offset}&search=${data?.search}`,

      providesTags: ["productType"],
    }),

    getSocialProductTypeData: builder.query({
      query: (data) => {
        const { search = "", category = "" } = data;
        return `${socialApi}/product-type/?limit=${data.limit}&offset=${data.offset}&search=${search}&JewelleryCategoryModel_product_type=${category}`;
      },
      providesTags: ["productType-social"],
    }),
    getSocialSubCategoryData: builder.query({
      query: (data) => {
        const {
          category = "",
          product_type = "",
          search = "",
          limit = 6,
          offset = 6,
        } = data;
        return `${socialApi}/sub-categories/?limit=${limit}&offset=${offset}&search=${search}&category=${category}&product_type=${product_type}`;
      },

      providesTags: ["sub-category-social"],
    }),
    getSocialSubSubCategoryData: builder.query({
      query: (data) => {
        const {
          category = "",
          product_type = "",
          search = "",
          sub_category = "",
          limit = 6,
          offset = 6,
        } = data;
        return `${socialApi}/sub-sub-categories/?limit=${limit}&offset=${offset}&search=${search}&category=${category}&product_type=${product_type}&sub_category=${sub_category}`;
      },

      providesTags: ["sub-sub-category-social"],
    }),

    getSocialVendorData: builder.query({
      query: (data) => ({
        url: `${socialApi}/supplier/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
      }),
      providesTags: ["vendor-social-media"],
    }),
    getBestSellerProductTypeData: builder.query({
      query: (data) => {
        const { category = "", search = "" } = data;
        return `${bestSellerApi}/product-type/?limit=${data.limit}&offset=${data.offset}&search=${search}&JewelleryCategoryModel_product_type=${category}`;
      },

      providesTags: ["productType-best-seller"],
    }),

    getBestSellerSubCategoryData: builder.query({
      query: (data) => {
        const { category = "", search = "" } = data;
        return `${bestSellerApi}/sub-categories/?limit=${data.limit}&offset=${data.offset}&search=${search}&JewelleryCategoryModel_product_type=${category}`;
      },

      providesTags: ["sub-category-best-seller"],
    }),

    getBestSellerSubSubCategoryData: builder.query({
      query: (data) => {
        const { category = "", search = "" } = data;
        return `${bestSellerApi}/sub-sub-categories/?limit=${data.limit}&offset=${data.offset}&search=${search}&JewelleryCategoryModel_product_type=${category}`;
      },

      providesTags: ["sub-sub-category-best-seller"],
    }),

    getBestSellerDepartmentData: builder.query({
      query: (data) => ({
        url: `${bestSellerApi}/department/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
      }),
      providesTags: ["department-best-seller"],
    }),
    getBestSellerVendorData: builder.query({
      query: (data) => ({
        url: `${bestSellerApi}/supplier/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
      }),
      providesTags: ["supplier-best-seller"],
    }),
    getBestSellerRetailData: builder.query({
      query: (data) => {
        const { search = "" } = data;
        return `${bestSellerApi}/retail/?limit=${data.limit}&offset=${data.offset}&search=${search}`;
      },
      providesTags: ["vendor-best-seller"],
    }),
    getBestSellerKarigarLocationData: builder.query({
      query: (data) => ({
        url: `${bestSellerApi}/karigar-location/?limit=${data.limit}&offset=${data.offset}&search=${data.search}`,
      }),
      providesTags: ["karigar-location-best-seller"],
    }),
    getBestSellerLocationData: builder.query({
      query: (data) => {
        const { search = "" } = data;
        return `${bestSellerApi}/locations/?limit=${data.limit}&offset=${data.offset}&search=${search}`;
      },
      providesTags: ["location-best-seller"],
    }),

    getWipProductTypeData: builder.query({
      query: (data) =>
        data?.category
          ? `${wipClientAdmin}/product-type/?limit=${data.limit}&offset=${data.offset}&search=${data?.search}&JewelleryCategoryModel_product_type=${data?.category}`
          : `${wipClientAdmin}/product-type/?limit=${data.limit}&offset=${data.offset}&search=${data?.search}`,

      providesTags: ["productType-wip"],
    }),

    getDesignBankProductTypeData: builder.query({
      query: (data) =>
        data?.category
          ? `${designBankClientAdmin}/product-type/?limit=${data.limit}&offset=${data.offset}&search=${data?.search}&JewelleryCategoryModel_product_type=${data?.category}`
          : `${designBankClientAdmin}/product-type/?limit=${data.limit}&offset=${data.offset}&search=${data?.search}`,

      providesTags: ["productType-design-bank"],
    }),
    updateJewelProductType: builder.mutation({
      query: (data) => ({
        url: `${jewelleryApi}/jewellery-product-type/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["productType"],
    }),

    updateSocialProductType: builder.mutation({
      query: (data) => ({
        url: `${socialApi}/product-type/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["productType-social"],
    }),
    updateBestSellerProductType: builder.mutation({
      query: (data) => ({
        url: `${bestSellerApi}/product-type/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["productType-best-seller"],
    }),
    updateBestSellerLocationType: builder.mutation({
      query: (data) => ({
        url: `${bestSellerApi}/karigar-location/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["location-best-seller"],
    }),
    updateDesignBankProductType: builder.mutation({
      query: (data) => ({
        url: `${designBankClientAdmin}/product-type/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["productType-design-bank"],
    }),
    updateProductTypeWip: builder.mutation({
      query: (data) => ({
        url: `${wipClientAdmin}/product-type/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["productType-wip"],
    }),

    updateIpAddressStatus: builder.mutation({
      query: (data) => ({
        url: `${customutilsApi}/ip-address/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["all-ip-address"],
    }),

    updateJewelClassification: builder.mutation({
      query: (data) => ({
        url: `${productsApi}/product-classification/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["filters"],
    }),

    updateSocialClassification: builder.mutation({
      query: (data) => ({
        url: `${socialApi}/classification/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["filters"],
    }),

    updateBestSellerClassification: builder.mutation({
      query: (data) => ({
        url: `${bestSellerApi}/classification/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["filters"],
    }),

    updateWipClassification: builder.mutation({
      query: (data) => ({
        url: `${wipClientAdmin}/classification/${data.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["filters"],
    }),

    //get All Locations Data
    getAllLocations: builder.query({
      query: (data) =>
        `${productsApi}/product-locations/?limit=${data?.limit}&offset=${data?.offset}&search=${data?.search}`,

      providesTags: ["filters"],
    }),

    getAllProductsDepartments: builder.query({
      query: (data) => {
        const { search = "", locations = "" } = data;
        return `${productsApi}/product-department/?limit=${data?.limit}&offset=${data?.offset}&search=${search}&location_ids=${locations}`;
      },
      providesTags: ["filters"],
    }),

    getVenderData: builder.query({
      query: (data) =>
        `${productsApi}/product-vendor/?limit=${data?.limit}&offset=${data?.offset}&search=${data?.search}`,
    }),

    getSocialVenderData: builder.query({
      query: (data) => {
        const { search = "" } = data;
        return `${socialApi}/supplier/?limit=${data?.limit}&offset=${data?.offset}&search=${search}`;
      },
    }),

    getBestSellerVenderData: builder.query({
      query: (data) =>
        `${bestSellerApi}/supplier/?limit=${data?.limit}&offset=${data?.offset}&search=${data?.search}`,
    }),

    //get All Departments Data
    getAllDepartments: builder.query({
      query: (data) =>
        `${productsApi}/get-departments/?location_ids=[${data?.locationIds}]&limit=${data?.limit}&offset=${data?.offset}&search=${data?.search}`,
    }),

    //get Download File
    getDownloadFile: builder.query({
      query: (data) => `${customutilsApi}/excel-export-download/${data?.slug}/`,
    }),

    //update user Details
    updateUserDetails: builder.mutation({
      query: (data) => ({
        url: `${accountsApi}/assigned-user-departments-locations-update/${data.slug}/`,
        method: "PUT",
        body: data.data,
      }),
      invalidatesTags: ["editedUserData"],
    }),

    pdfGenerate: builder.mutation({
      query: (data) => ({
        url: `${customutilsApi}/pdf-genrate/`,
        method: "POST",
        body: data,
      }),
    }),
    pdfGenerateBestSeller: builder.mutation({
      query: (data) => ({
        url: `${bestSellerApi}/pdf-generate/`,
        method: "POST",
        body: data,
      }),
    }),
    pdfGenerateSocialMedia: builder.mutation({
      query: (data) => ({
        url: `${socialApi}/pdf-generate/`,
        method: "POST",
        body: data,
      }),
    }),
    pdfGenerateWip: builder.mutation({
      query: (data) => ({
        url: `${wipClientAdmin}/pdf-generate/`,
        method: "POST",
        body: data,
      }),
    }),
    pdfGenerateDesignBank: builder.mutation({
      query: (data) => ({
        url: `${designBankClientAdmin}/pdf-generate/`,
        method: "POST",
        body: data,
      }),
    }),
    bestSellerAssignUserLocation: builder.mutation({
      query: (data) => ({
        url: `${bestSellerApi}/assign-locations/`,
        method: "POST",
        body: data,
      }),
    }),

    //get Download File
    sendCategorySpecials: builder.mutation({
      query: (data) => ({
        url: `${jewelleryApi}/category-special/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["category"],
    }),

    //get Download File
    getSpecialCategoryType: builder.query({
      query: (data) => `${jewelleryApi}/special-category/${data?.slug}/`,
      providesTags: ["category"],
    }),

    sendActiveDeactivate: builder.mutation({
      query: (data) => ({
        url: `${accountsApi}/activate-deactivate-user/${data?.slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["userData", "editedUserData"],
    }),
    //......................................Bulk Upload Ends.............................................//
  }),
});
export const {
  useCustomerLoginMutation,
  useLazyGetCustomerLoginDetailsQuery,
  useGetFilterCityDataQuery,
  useLazyGetCustomerVisitStoreQuery,
  useGetAllCityDetailsQuery,
  useGetAllStateDetailsQuery,
  useGetCountriesListQuery,
  useCustomerRegistrationMutation,
  useGetCustomerVisitStoreQuery,
  useGetSalesProfileDetailsQuery,
  useGetCustomerDetailsQuery,
  useAddUpdateCustomerMutation,
  useGetCustomerProfileUpdateMutation,
  useGetCatalogueCategoryFiltersQuery,
  useLazyGetCatalogueCategoryFiltersQuery,
  useGetCatalogueProductDetailsQuery,
  useGetBestSellerProductDetailsQuery,
  useGetSocialMediaProductDetailsQuery,
  useGetExtimatePriceDetailsQuery,
  // useGetCatalogueCategoryFiltersQuery,
  useGetCatalogueSubCategoryFiltersQuery,
  useLazyGetCatalogueSubCategoryFiltersQuery,
  useGetLocationFiltersQuery,
  useLazyGetLocationFiltersQuery,
  useGetMetalColorFiltersQuery,
  useGetSelectedSizeFiltersQuery,
  useGetCatalogueProductTypeFiltersQuery,
  useLazyGetCatalogueProductTypeFiltersQuery,
  useGetTrendingProductsQuery,
  useGetBestSellerProductsSalesQuery,
  useGetSocialMediaProductsSalesQuery,
  useLazyGetSocialMediaProductsSalesQuery,
  useGetCurrentTrendsProductsQuery,
  useGetTimelessProductsQuery,
  useGetUserWishlistDatesQuery,
  useGetUserWishlistDatesInventoryQuery,
  useGetUserWishlistDatesSocialMediaQuery,
  useGetUserWishlistDatesBestSellerQuery,
  useGetWishlistByDateQuery,
  useAddToWishListMutation,
  useGetWishlistByDateInventoryQuery,
  useGetInventoryRelatedProductsQuery,
  useGetWishlistByDateBestSellerQuery,
  useGetWishlistByDateSocialMediaQuery,
  useGetDesignNamesQuery,
  useLazyGetDesignNamesQuery,
  useGetDesignSourcesQuery,
  useLazyGetDesignSourcesQuery,
  useAddToWishListWithTypesMutation,
  useGetCatalogueProductsQuery,
  useHandleConditionEstimatePriceDetailsMutation,
  useHandleSocialConditionEstimatePriceDetailsMutation,
  useHandleBestSellerConditionEstimatePriceDetailsMutation,
  useHandleLogoutMutation,
  useGetCatalogueSubSubCategoryTypeQuery,
  useLazyGetCatalogueSubSubCategoryTypeQuery,
  useLazyGetCityDataRefreshQuery,
  useGetProductClassificationFiltersQuery,
  useGetAllProductSizesQuery,
  useLazyGetAllProductSizesQuery,
  useGetSocialClassificationFiltersQuery,
  useLazyGetSocialClassificationFiltersQuery,
  useGetBestSellerEstimatePriceDetailsQuery,
  useGetDesignBankEstimatePriceDetailsQuery,
  useGetWipEstimatePriceDetailsQuery,
  useGetSocialEstimatePriceDetailsQuery,
  useGetBestSellerClassificationFiltersQuery,
  useLazyGetBestSellerClassificationFiltersQuery,
  useGetAllProductsDepartmentsQuery,
  useLazyGetAllProductsDepartmentsQuery,
  useGetWipClassificationFiltersQuery,
  useLazyGetProductClassificationFiltersQuery,
  useHandleWhatsAppShareQuery,
  useHandleWishlistShareQuery,
  useAddToWishScanProductMutation,
  useAddScanToProductDetailsMutation,
  useHandleEstimateStatusUpdateMutation,
  useChangeSalesPasswordMutation,
  useGetFilesUploadedDateQuery,

  // bulk upload
  useGetAllBulkuploadFilesQuery,
  useGetAllIpAddressQuery,
  useGetAllSynergicsFinanceFileQuery,
  useAddSynergicsUserCredMutation,
  useGetAllSynergicsUserCredQuery,
  useAddSynergicsFinanceFileMutation,
  useHandleUploadFileMutation,
  useGetUsersDataQuery,
  useHandleUpdateGoldPriceMutation,
  useGetMetalPriceDataQuery,
  useHandleAddUserMutation,
  useGetJewelleryCategoryDataQuery,
  useGetSocialCategoryDataQuery,
  useLazyGetSocialCategoryDataQuery,
  useGetBestSellerCategoryDataQuery,
  useLazyGetBestSellerCategoryDataQuery,
  useGetWipCategoryDataQuery,
  useGetDesignBankCategoryDataQuery,
  useGetWipSubCategoryDataQuery,
  useGetDesignBankSubCategoryDataQuery,
  useGetWipSubSubCategoryDataQuery,
  useGetDesignBankSubSubCategoryDataQuery,
  useGetJewelleryProductTypeDataQuery,
  useGetSocialProductTypeDataQuery,
  useLazyGetSocialProductTypeDataQuery,
  useGetBestSellerProductTypeDataQuery,
  useLazyGetBestSellerProductTypeDataQuery,
  useGetWipProductTypeDataQuery,
  useGetDesignBankProductTypeDataQuery,
  useUpdateJewelProductTypeMutation,
  useUpdateSocialProductTypeMutation,
  useUpdateBestSellerProductTypeMutation,
  useBestSellerAssignUserLocationMutation,
  useUpdateBestSellerLocationTypeMutation,
  useUpdateDesignBankProductTypeMutation,
  useUpdateProductTypeWipMutation,
  useUpdateIpAddressStatusMutation,
  useUpdateJewelCategoryMutation,
  useUpdateSocialCategoryMutation,
  useUpdateBestSellerCategoryMutation,
  useUpdateDesignBankSubCategoryMutation,
  useUpdateDesignBankSubSubCategoryMutation,
  useUpdateDesignBankCategoryMutation,
  useUpdateWipSubCategoryMutation,
  useUpdateWipSubSubCategoryMutation,
  useUpdateWipCategoryMutation,
  useGetAllLocationsQuery,
  useGetAllDepartmentsQuery,
  useGetDownloadFileQuery,
  useGetUserDetailQuery,
  useUpdateUserDetailsMutation,
  useGetBulkCatalogueProductsQuery,
  useGetBulkBestSellerProductsQuery,
  useGetBulkWipProductsQuery,
  useGetBulkDesignBankProductsQuery,
  useGetBulkSocialProductsQuery,
  useGetBulkSocialProductsTotalCalculationQuery,
  useGetBulkBestSellerProductsTotalCalculationQuery,
  useGetBulkDesignBankProductsTotalCalculationQuery,
  useGetBulkInventoryProductsTotalCalculationQuery,
  useGetBulkWipProductsTotalCalculationQuery,
  usePdfGenerateMutation,
  usePdfGenerateWipMutation,
  usePdfGenerateDesignBankMutation,
  usePdfGenerateBestSellerMutation,
  usePdfGenerateSocialMediaMutation,
  useGetVenderDataQuery,
  useGetSocialVenderDataQuery,
  useLazyGetSocialVenderDataQuery,
  useGetBestSellerVenderDataQuery,
  useGetCatalogueSubCategoryFiltersSalesQuery,
  useGetSocialCatalogueSubCategoryFiltersSalesQuery,
  useLazyGetSocialCatalogueSubCategoryFiltersSalesQuery,
  useGetBestSellerCatalogueSubCategoryFiltersSalesQuery,
  useLazyGetBestSellerCatalogueSubCategoryFiltersSalesQuery,
  useGetWipCatalogueSubCategoryFiltersSalesQuery,
  useGetDesignBankCatalogueSubCategoryFiltersSalesQuery,
  useGetWipStatusReportFiltersSalesQuery,
  useGetWipStatusDueFiltersSalesQuery,
  useGetWipSupplierFiltersSalesQuery,
  useGetWipKarigarLocationFiltersSalesQuery,
  useGetWipIndentLocationsFiltersSalesQuery,
  useGetSocialCatalogueSubSubCategoryFiltersSalesQuery,
  useGetBestSellerCatalogueSubSubCategoryFiltersSalesQuery,
  useGetWipCatalogueSubSubCategoryFiltersSalesQuery,
  useGetDesignBankCatalogueSubSubCategoryFiltersSalesQuery,
  useGetDesignBankClassificationDataQuery,
  useGetDesignBankPolishDataQuery,
  useGetDesignBankPurityDataQuery,
  useGetDesignBankCollectionDataQuery,
  useGetDesignBankOccationDataQuery,
  useAddToIpAddressMutation,
  useLazyGetCatalogueSubCategoryFiltersSalesQuery,
  useSendCategorySpecialsMutation,
  useGetSpecialCategoryTypeQuery,
  useSendActiveDeactivateMutation,
  useUpdateJewelClassificationMutation,
  useUpdateSocialClassificationMutation,
  useUpdateBestSellerClassificationMutation,
  useUpdateWipClassificationMutation,
  useUpdateProductLocationsMutation,
  useGetBestSellerSubCategoryDataQuery,
  useGetBestSellerSubSubCategoryDataQuery,
  useGetBestSellerDepartmentDataQuery,
  useGetBestSellerVendorDataQuery,
  useGetBestSellerRetailDataQuery,
  useLazyGetBestSellerRetailDataQuery,
  useGetBestSellerLocationDataQuery,
  useLazyGetBestSellerLocationDataQuery,
  useGetBestSellerKarigarLocationDataQuery,
  useGetProductsCatalogueQuery,
  useGetBulkCatalogueAdminQuery,

  useGetSocialSubCategoryDataQuery,
  useGetSocialSubSubCategoryDataQuery,
  useGetSocialVendorDataQuery,
} = restApi;

export default restApi;
