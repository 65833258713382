// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import todo from "@src/views/apps/todo/store";
import chat from "@src/views/apps/chat/store";
import users from "@src/views/apps/user/store";
import email from "@src/views/apps/email/store";
import invoice from "@src/views/apps/invoice/store";
import calendar from "@src/views/apps/calendar/store";
import ecommerce from "@src/views/apps/ecommerce/store";
import dataTables from "@src/views/tables/data-tables/store";
import permissions from "@src/views/apps/roles-permissions/store";
import popup from "./slices/popup";
import restApi from "./apis/restApis";
import customerLogin from "./slices/customerLogin";
import catalogue from "./slices/catalogueViewer";
import bestSellerSocialMedia from "./slices/bestSellerSocialMediaViewer";
import productFilter from "./slices/productFilter";

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,

  popup,
  customerLogin,
  catalogue,
  bestSellerSocialMedia,
  productFilter,
  [restApi.reducerPath]: restApi.reducer,
};

export default rootReducer;
