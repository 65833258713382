import { createSlice } from "@reduxjs/toolkit";

const item = localStorage.getItem("filters");
const minPrice = localStorage.getItem("minPrice");
const maxPrice = localStorage.getItem("maxPrice");
const minNetWeight = localStorage.getItem("minWeight");
const maxNetWeight = localStorage.getItem("maxWeight");
const minDiamondWeight = localStorage.getItem("minDiamondWeight");
const maxDiamondWeight = localStorage.getItem("maxDiamondWeight");
const ordering = localStorage.getItem("ordering");
const search = localStorage.getItem("catalogueProductSearch");

const initialState = {
  filters: item ? JSON.parse(item) : [],
  minPrice: minPrice ? JSON.parse(minPrice) : "",
  maxPrice: maxPrice ? JSON.parse(maxPrice) : "",
  minWeight: minNetWeight ? JSON.parse(minNetWeight) : "",
  maxWeight: maxNetWeight ? JSON.parse(maxNetWeight) : "",
  minDiamondWeight: minDiamondWeight ? JSON.parse(minDiamondWeight) : "",
  maxDiamondWeight: maxDiamondWeight ? JSON.parse(maxDiamondWeight) : "",
  ordering: ordering ? JSON.parse(ordering) : "",
  catalogueProductSearch: search ? search : "",

  adminInventorySearch: "",
  adminCatalogueSearch: "",
  adminSocialMediaSearch: "",
  adminBestSellerSearch: "",
  adminWipSearch: "",
  adminDesignBankSearch: "",

  estimateStoneDetails: [],
  wishlistProductCurrentPage: 1,
  wishlistProductOffset: 0,

  inventoryOffset: 0,
  inventoryCurrentPage: 1,

  catalogueOffset: 0,
  catalogueCurrentPage: 1,

  timelessOffset: 0,
  timelessCurrentPage: 1,

  trendingOffset: 0,
  trendingCurrentPage: 1,

  bestSellingOffset: 0,
  bestSellingCurrentPage: 1,

  socialMediaOffset: 0,
  socialMediaCurrentPage: 1,

  currentTrendingOffset: 0,
  currentTrendingCurrentPage: 1,

  searchToggle: false,

  setTime: "",

  filterByAndLastUpdated: null,
  filterCount: null,
};

const catalogueViewerSlice = createSlice({
  name: "catalogue",
  initialState: initialState,
  reducers: {
    setFilter(state, action) {
      localStorage.setItem("filters", JSON.stringify(action.payload));

      state.filters = action.payload;
    },

    setCustomSearch(state, action) {
      state[action.payload.type] = action.payload.value;
    },

    setOrdering(state, action) {
      localStorage.setItem("ordering", JSON.stringify(action.payload));
      console.log("ordering", action.payload);
      state.ordering = action.payload;
    },

    setMinPrice(state, action) {
      state.minPrice = action.payload;
      localStorage.setItem("minPrice", JSON.stringify(action.payload));
    },

    setMaxPrice(state, action) {
      state.maxPrice = action.payload;
      localStorage.setItem("maxPrice", JSON.stringify(action.payload));
    },

    setMinWeight(state, action) {
      state.minWeight = action.payload;
      localStorage.setItem("minWeight", JSON.stringify(action.payload));
    },

    setMaxWeight(state, action) {
      state.maxWeight = action.payload;
      localStorage.setItem("maxWeight", JSON.stringify(action.payload));
    },

    setMinDiamondWeight(state, action) {
      state.minDiamondWeight = action.payload;
      localStorage.setItem("minDiamondWeight", JSON.stringify(action.payload));
    },

    setMaxDiamondWeight(state, action) {
      state.maxDiamondWeight = action.payload;
      localStorage.setItem("maxDiamondWeight", JSON.stringify(action.payload));
    },

    clearFilters(state, action) {
      state.filters = [];
      state.minPrice = "";
      state.maxPrice = "";
      state.minWeight = "";
      state.maxWeight = "";
      state.minDiamondWeight = "";
      state.maxDiamondWeight = "";
      state.ordering = "";
      state.catalogueProductSearch = "";
      localStorage.setItem("filters", []);
      localStorage.setItem("minPrice", "");
      localStorage.setItem("maxPrice", "");
      localStorage.setItem("minWeight", "");
      localStorage.setItem("maxWeight", "");
      localStorage.setItem("minDiamondWeight", "");
      localStorage.setItem("maxDiamondWeight", "");
      localStorage.setItem("ordering", "");
      localStorage.setItem("catalogueProductSearch", "");
    },

    setMountingEstimateStoneDetails(state, action) {
      state.estimateStoneDetails = action.payload;
    },
    setEstimateStoneDetails(state, action) {
      const index = state.estimateStoneDetails?.findIndex(
        (each) => each.id === action.payload[0]
      );
      state.estimateStoneDetails[index].stone_rate = action.payload[1];
    },

    addWishListProductCurrentPage(state, action) {
      state.wishlistProductCurrentPage = action.payload;
    },
    addWishListProductOffset(state, action) {
      state.wishlistProductOffset = action.payload;
    },

    setSocialMediaOffset(state, action) {
      state.socialMediaOffset = action.payload;
    },

    setSocialMediaPage(state, action) {
      state.socialMediaCurrentPage = action.payload;
    },

    setCatalogueOffset(state, action) {
      state.catalogueOffset = action.payload;
    },

    setCataloguePage(state, action) {
      state.catalogueCurrentPage = action.payload;
    },

    setInventoryOffset(state, action) {
      state.inventoryOffset = action.payload;
    },

    setInventoryPage(state, action) {
      state.inventoryCurrentPage = action.payload;
    },

    setTimelessOffset(state, action) {
      state.timelessOffset = action.payload;
    },

    setTimelessPage(state, action) {
      state.timelessCurrentPage = action.payload;
    },

    setTrendingOffset(state, action) {
      state.trendingOffset = action.payload;
    },

    setTrendingPage(state, action) {
      state.trendingCurrentPage = action.payload;
    },

    setBestSellingOffset(state, action) {
      state.bestSellingOffset = action.payload;
    },

    setBestSellingPage(state, action) {
      state.bestSellingCurrentPage = action.payload;
    },

    setCurrentTrendingOffset(state, action) {
      state.currentTrendingOffset = action.payload;
    },

    setCurrentTrendingPage(state, action) {
      state.currentTrendingCurrentPage = action.payload;
    },

    resetPagination(state, action) {
      state.inventoryOffset = 0;
      state.inventoryCurrentPage = 1;

      state.catalogueOffset = 0;
      state.catalogueCurrentPage = 1;

      state.socialMediaOffset = 0;
      state.socialMediaCurrentPage = 1;

      state.timelessOffset = 0;
      state.timelessCurrentPage = 1;

      state.trendingOffset = 0;
      state.trendingCurrentPage = 1;

      state.bestSellingOffset = 0;
      state.bestSellingCurrentPage = 1;

      state.currentTrendingOffset = 0;
      state.currentTrendingCurrentPage = 1;
    },

    SearchCatalogueProducts(state, action) {
      state.catalogueProductSearch = action.payload;

      localStorage.setItem("catalogueProductSearch", action.payload);
    },
    clearSearchProducts(state, action) {
      state.catalogueProductSearch = "";

      localStorage.setItem("catalogueProductSearch", "");
    },

    onSearchField(state, action) {
      state.searchToggle = true;
    },

    offSearchField(state, action) {
      state.searchToggle = false;
    },

    clearTimeout(state, action) {
      state.setTime = action.payload;
    },

    setFilterByDataAndLastUpdated(state, action) {
      state.filterByAndLastUpdated = action.payload;
    },

    setFilterCount(state, action) {
      state.filterCount = action.payload;
    },
  },
});

export const {
  setFilter,
  clearFilters,
  setMinPrice,
  setMaxPrice,
  setEstimateStoneDetails,
  setMountingEstimateStoneDetails,
  addWishListProductCurrentPage,
  addWishListProductOffset,
  setInventoryOffset,
  setInventoryPage,
  setOrdering,
  setCatalogueOffset,
  setCataloguePage,
  setTimelessOffset,
  setTimelessPage,
  setBestSellingOffset,
  setBestSellingPage,
  setSocialMediaOffset,
  setSocialMediaPage,
  setTrendingOffset,
  setTrendingPage,
  setCurrentTrendingOffset,
  setCurrentTrendingPage,
  resetPagination,
  SearchCatalogueProducts,
  clearSearchProducts,
  setMaxWeight,
  setMinWeight,
  offSearchField,
  onSearchField,
  clearTimeout,
  setFilterByDataAndLastUpdated,
  setFilterCount,
  setMinDiamondWeight,
  setMaxDiamondWeight,
  setCustomSearch,
} = catalogueViewerSlice.actions;
export default catalogueViewerSlice.reducer;
