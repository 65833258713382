import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: null,
  refreshToken: null,
  customerData: null,
  customerMobileNumber: null,
};

const customerLoginSlice = createSlice({
  name: `customerLogin`,
  initialState: initialState,
  reducers: {
    addCustomerToken(state, action) {
      localStorage.setItem("customer", JSON.stringify(action.payload));
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.customerData = action.payload;
    },

    removeCustomerToken(state, action) {
      localStorage.setItem("customer", null);
      state.accessToken = null;
      state.refreshToken = null;
      state.customerData = null;
    },

    addCustomerMobileNumber(state, action) {
      state.customerMobileNumber = action.payload;
    },
  },
});

export const {
  addCustomerToken,
  removeCustomerToken,
  addCustomerMobileNumber,
} = customerLoginSlice.actions;

export default customerLoginSlice.reducer;
