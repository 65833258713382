import { createSlice } from "@reduxjs/toolkit";
import { IoConstructOutline } from "react-icons/io5";

const initial = {
  customerCheckin: {
    customerEntey: false,
    customerRegistation: "",
    customerProfileEdit: { status: false, helperData: "" },

    customerProfileImage: false,
    productDetailsEstimatePrice: { status: false, helperData: null },
    productBestSellerDetailsEstimatePrice: { status: false, helperData: null },
    productSocialMediaDetailsEstimatePrice: { status: false, helperData: null },
    productEstimatePrice: { status: false, helperData: null },
    socialProductEstimatePrice: { status: false, helperData: null },
    bestSellerProductEstimatePrice: { status: false, helperData: null },
    designBankProductEstimatePrice: { status: false, helperData: null },
    wipProductEstimatePrice: { status: false, helperData: null },
    EstimateWithoutRate: { status: false, helperData: null },
    EstimateWithRate: { status: false, helperData: null },
    livePrice: false,
  },
  print: { status: false, helperData: null },
  socialMedia: "",
  BarCodeScan: { status: false, helperData: "" },
  productCodeScan: { status: false, helperData: "" },
  pricePopup: { status: false, helperData: "" },
  changePasswordPopup: { status: false, helperData: "" },
  discountPopup: { status: false, helperData: "" },
  ProductTypePopup: { status: false, helperData: "" },
  bestSellerLocation: { status: false, helperData: "" },
  IpAddressPopup: { status: false, helperData: "" },
  synergicsUserCredPopup: { status: false, helperData: "" },
  synergicsFinanceFilePopup: { status: false, helperData: "" },
  categoryPopup: { status: false, helperData: "" },
  classificationTypePopup: { status: false, helperData: "" },
  locationTypePopup: { status: false, helperData: "" },
  makingCharge: "",
  purityValue: "",

  // bulk upload
  userAddPopup: { status: false, helperData: "" },
  uploadPopup: { status: false, helperData: "" },
  uploadPopupBestSeller: { status: false, helperData: "" },
  uploadPopupWip: { status: false, helperData: "" },
  uploadPopupDesignBank: { status: false, helperData: "" },
  uploadPopupSocialMedia: { status: false, helperData: "" },
  goldPricePopup: { status: false, helperData: "" },
  editGoldPrice: { state: false, helperData: "" },
  userEditPopup: { status: false, helperData: "" },
  selectedImagesPopup: { status: false, helperData: null },
  imagesListCarouselPopup: { status: false, helperData: null },
  carouselIndex: null,
  categoryTypePopup: { status: false, helperData: null },
  addIpAddressPopup: { status: false, helperData: null },
  editLocation: { status: false, helperData: null },
  ipAddress: { address: null },
  calculateFiltersPopup: { status: false, helperData: null },
};

const customerCheckIn = createSlice({
  name: "customer",
  initialState: initial,
  reducers: {
    setIpAddress(state, action) {
      state.ipAddress.address = action.payload;
      localStorage.setItem("ipAddress", JSON.stringify(action.payload));
    },

    openCustomerEnteyPopup(state, action) {
      state.customerCheckin.customerEntey = true;
    },
    closeCustomerEnteyPopup(state, action) {
      state.customerCheckin.customerEntey = false;
    },

    addCustomerRegistationDetails(state, action) {
      state.customerCheckin.customerRegistation = action.payload;
    },

    openCustomerProfileEditPopup(state, action) {
      state.customerCheckin.customerProfileEdit.status = true;
      state.customerCheckin.customerProfileEdit.helperData = action.payload;
    },
    closeCustomerProfileEditPopup(state, action) {
      state.customerCheckin.customerProfileEdit.status = false;
    },

    openCustomerProfileImagePopup(state, action) {
      state.customerCheckin.customerProfileImage = true;
    },
    closeCustomerProfileImagePopup(state, action) {
      state.customerCheckin.customerProfileImage = false;
    },

    openProductDetailsEstimatePricePopup(state, action) {
      state.customerCheckin.productDetailsEstimatePrice.status = true;
      state.customerCheckin.productDetailsEstimatePrice.helperData =
        action.payload;
    },
    closeProductDetailsEstimatePricePopup(state, action) {
      state.customerCheckin.productDetailsEstimatePrice.status = false;
      state.customerCheckin.productDetailsEstimatePrice.helperData =
        action.payload;
    },

    openBestSellerProductDetailsEstimatePricePopup(state, action) {
      state.customerCheckin.productBestSellerDetailsEstimatePrice.status = true;
      state.customerCheckin.productBestSellerDetailsEstimatePrice.helperData =
        action.payload;
    },
    closeBestSellerProductDetailsEstimatePricePopup(state, action) {
      state.customerCheckin.productBestSellerDetailsEstimatePrice.status = false;
      state.customerCheckin.productBestSellerDetailsEstimatePrice.helperData =
        action.payload;
    },

    openSocialMediaProductDetailsEstimatePricePopup(state, action) {
      state.customerCheckin.productSocialMediaDetailsEstimatePrice.status = true;
      state.customerCheckin.productSocialMediaDetailsEstimatePrice.helperData =
        action.payload;
    },
    closeSocialMediaProductDetailsEstimatePricePopup(state, action) {
      state.customerCheckin.productSocialMediaDetailsEstimatePrice.status = false;
      state.customerCheckin.productSocialMediaDetailsEstimatePrice.helperData =
        action.payload;
    },

    openProductEstimatePricePopup(state, action) {
      state.customerCheckin.productEstimatePrice.status = true;
      state.customerCheckin.productEstimatePrice.helperData = action.payload;
    },
    closeProductEstimatePricePopup(state, action) {
      state.customerCheckin.productEstimatePrice.status = false;
      state.customerCheckin.productEstimatePrice.helperData = action.payload;
    },

    openSocialProductEstimatePricePopup(state, action) {
      state.customerCheckin.socialProductEstimatePrice.status = true;
      state.customerCheckin.socialProductEstimatePrice.helperData =
        action.payload;
    },
    closeSocialProductEstimatePricePopup(state, action) {
      state.customerCheckin.socialProductEstimatePrice.status = false;
      state.customerCheckin.socialProductEstimatePrice.helperData =
        action.payload;
    },

    openBestSellerProductEstimatePricePopup(state, action) {
      state.customerCheckin.bestSellerProductEstimatePrice.status = true;
      state.customerCheckin.bestSellerProductEstimatePrice.helperData =
        action.payload;
    },
    closeBestSellerProductEstimatePricePopup(state, action) {
      state.customerCheckin.bestSellerProductEstimatePrice.status = false;
      state.customerCheckin.bestSellerProductEstimatePrice.helperData =
        action.payload;
    },
    openDesignBankProductEstimatePricePopup(state, action) {
      state.customerCheckin.designBankProductEstimatePrice.status = true;
      state.customerCheckin.designBankProductEstimatePrice.helperData =
        action.payload;
    },
    closeDesignBankProductEstimatePricePopup(state, action) {
      state.customerCheckin.designBankProductEstimatePrice.status = false;
      state.customerCheckin.designBankProductEstimatePrice.helperData =
        action.payload;
    },

    openWipProductEstimatePricePopup(state, action) {
      state.customerCheckin.wipProductEstimatePrice.status = true;
      state.customerCheckin.wipProductEstimatePrice.helperData = action.payload;
    },
    closeWipProductEstimatePricePopup(state, action) {
      state.customerCheckin.wipProductEstimatePrice.status = false;
      state.customerCheckin.wipProductEstimatePrice.helperData = action.payload;
    },

    openEstimateWithoutRatePopup(state, action) {
      state.customerCheckin.EstimateWithoutRate.status = true;
      state.customerCheckin.EstimateWithoutRate.helperData = action.payload;
    },
    closeEstimateWithoutRatePopup(state, action) {
      state.customerCheckin.EstimateWithoutRate.status = false;
      state.customerCheckin.EstimateWithoutRate.helperData = action.payload;
    },

    openEstimateWithRatePopup(state, action) {
      state.customerCheckin.EstimateWithRate.status = true;
      state.customerCheckin.EstimateWithRate.helperData = action.payload;
    },
    closeEstimateWithRatePopup(state, action) {
      state.customerCheckin.EstimateWithRate.status = false;
      state.customerCheckin.EstimateWithRate.helperData = "";
    },

    openLivePricePopup(state, action) {
      state.customerCheckin.livePrice = true;
    },
    closeLivePricePopup(state, action) {
      state.customerCheckin.livePrice = false;
    },

    addSocialMedia(state, action) {
      state.socialMedia = action.payload;
    },

    openBarCodeScanner(state, action) {
      state.BarCodeScan.status = true;
    },
    closeBarCodeScanner(state, action) {
      state.BarCodeScan.status = false;
    },

    openProductCodeScanScanner(state, action) {
      state.productCodeScan.status = true;
    },
    closeProductCodeScanScanner(state, action) {
      state.productCodeScan.status = false;
    },

    openDiscountPopup(state, action) {
      state.discountPopup.status = true;
      state.discountPopup.helperData = action.payload;
    },
    closeDiscountPopup(state, action) {
      state.discountPopup.status = false;
      state.discountPopup.helperData = null;
    },

    openProductTypePopup(state, action) {
      state.ProductTypePopup.status = true;
      state.ProductTypePopup.helperData = action.payload;
    },

    closeProductTypePopup(state, action) {
      state.ProductTypePopup.status = false;
      state.ProductTypePopup.helperData = null;
    },

    openBestSellerLocationPopup(state, action) {
      state.bestSellerLocation.status = true;
      state.bestSellerLocation.helperData = action.payload;
    },
    closeBestSellerLocationPopup(state, action) {
      state.bestSellerLocation.status = false;
      state.bestSellerLocation.helperData = null;
    },
    openIpAddressPopup(state, action) {
      state.IpAddressPopup.status = true;
      state.IpAddressPopup.helperData = action.payload;
    },
    closeIpAddressPopup(state, action) {
      state.IpAddressPopup.status = false;
      state.IpAddressPopup.helperData = null;
    },

    openCategoryPopup(state, action) {
      state.categoryPopup.status = true;
      state.categoryPopup.helperData = action.payload;
    },
    closeCategoryPopup(state, action) {
      state.categoryPopup.status = false;
      state.categoryPopup.helperData = null;
    },

    openClassificationPopup(state, action) {
      state.classificationTypePopup.status = true;
      state.classificationTypePopup.helperData = action.payload;
    },
    closeClassificationPopup(state, action) {
      state.classificationTypePopup.status = false;
      state.classificationTypePopup.helperData = null;
    },
    openLocationPopup(state, action) {
      state.locationTypePopup.status = true;
      state.locationTypePopup.helperData = action.payload;
    },
    closeLocationPopup(state, action) {
      state.locationTypePopup.status = false;
      state.locationTypePopup.helperData = null;
    },

    openChangePasswordPopup(state, action) {
      state.changePasswordPopup.status = true;
      state.changePasswordPopup.helperData = action.payload;
    },
    closeChangePasswordPopup(state, action) {
      state.changePasswordPopup.status = false;
      state.changePasswordPopup.helperData = null;
    },

    // bulk upload
    openUserPopup(state, action) {
      state.userAddPopup.status = true;
    },
    closeUserPopup(state, action) {
      state.userAddPopup.status = false;
    },
    openUploadPopup(state, action) {
      state.uploadPopup.status = true;
    },
    closeUploadPopup(state, action) {
      state.uploadPopup.status = false;
    },
    closeUploadBestSellerPopup(state, action) {
      state.uploadPopupBestSeller.status = false;
    },
    openUploadBestSellerPopup(state, action) {
      state.uploadPopupBestSeller.status = true;
    },

    closeUploadWipPopup(state, action) {
      state.uploadPopupWip.status = false;
    },
    openUploadWipPopup(state, action) {
      state.uploadPopupWip.status = true;
    },

    closeUploadDesignBankPopup(state, action) {
      state.uploadPopupDesignBank.status = false;
    },
    openUploadDesignBankPopup(state, action) {
      state.uploadPopupDesignBank.status = true;
    },

    closeUploadSocialMediaPopup(state, action) {
      state.uploadPopupSocialMedia.status = false;
    },
    openUploadSocialMediaPopup(state, action) {
      state.uploadPopupSocialMedia.status = true;
    },

    openGoldPricePopup(state, action) {
      state.goldPricePopup.status = true;
    },
    closeGoldPricePopup(state, action) {
      state.goldPricePopup.status = false;
    },
    openGoldPriceEditPopup(state, action) {
      state.editGoldPrice.status = true;
      state.editGoldPrice.helperData = action.payload;
    },
    closeGoldPriceEditPopup(state, action) {
      state.editGoldPrice.status = false;
    },
    openEditUserPopup(state, action) {
      state.userEditPopup.status = true;
      state.userEditPopup.helperData = action.payload;
    },
    closeEditUserPopup(state, action) {
      state.userEditPopup.status = false;
      state.userEditPopup.helperData = "";
    },

    openSelectedImagesPopup(state, action) {
      state.selectedImagesPopup.status = true;
      state.selectedImagesPopup.helperData = action.payload;
    },
    closeSelectedImagesPopup(state, action) {
      state.selectedImagesPopup.status = false;
      state.selectedImagesPopup.helperData = [];
    },

    openImagesListCarouselPopup(state, action) {
      state.imagesListCarouselPopup.helperData = action.payload;
      state.imagesListCarouselPopup.status = true;
    },

    setImagesListCarousel(state, action) {
      state.imagesListCarouselPopup.helperData = action.payload;
    },

    closeImagesListCarouselPopup(state, action) {
      state.imagesListCarouselPopup.status = false;
      state.imagesListCarouselPopup.helperData = null;
    },

    setCarouselIndex(state, action) {
      state.carouselIndex = action.payload;
    },

    openPricePopup(state, action) {
      state.pricePopup.status = true;
      state.pricePopup.helperData = action.payload;
    },
    closePricePopup(state, action) {
      state.pricePopup.status = false;
      state.pricePopup.helperData = action.payload;
    },
    openPrintPopup(state, action) {
      state.print.status = true;
      state.print.helperData = action.payload;
    },
    closePrintPopup(state, action) {
      state.print.status = false;
      state.print.helperData = action.payload;
    },
    setMakingChargesStore(state, action) {
      state.makingCharge = action.payload;
    },
    setPurityValueStore(state, action) {
      state.purityValue = action.payload;
    },

    openCategoryTypePopup(state, action) {
      state.categoryTypePopup.status = true;
      state.categoryTypePopup.helperData = action.payload;
    },
    closeCategoryTypePopup(state, action) {
      state.categoryTypePopup.status = false;
      state.categoryTypePopup.helperData = "";
    },

    closeAddIpAddressPopup(state, action) {
      state.addIpAddressPopup.status = false;
      state.addIpAddressPopup.helperData = "";
    },
    openAddIpAddressPopup(state, action) {
      state.addIpAddressPopup.status = true;
      state.addIpAddressPopup.helperData = "";
    },

    openSynergicsUserCredPopup(state, action) {
      state.synergicsUserCredPopup.status = true;
      state.synergicsUserCredPopup.helperData = "";
    },
    closeSynergicsUserCredPopup(state, action) {
      state.synergicsUserCredPopup.status = false;
      state.synergicsUserCredPopup.helperData = "";
    },
    openSynergicsFinanceFilePopup(state, action) {
      state.synergicsFinanceFilePopup.status = true;
      state.synergicsFinanceFilePopup.helperData = "";
    },

    closeSynergicsFinanceFilePopup(state, action) {
      state.synergicsFinanceFilePopup.status = false;
      state.synergicsFinanceFilePopup.helperData = "";
    },

    openEditLocationPopup(state, action) {
      state.editLocation.status = true;
      state.editLocation.helperData = action.payload;
    },
    closeEditLocationPopup(state, action) {
      state.editLocation.status = false;
      state.editLocation.helperData = "";
    },

    openCalculateFiltersPopup(state, action) {
      state.calculateFiltersPopup.status = true;
      state.calculateFiltersPopup.helperData = action.payload;
    },
    closeCalculateFiltersPopup(state, action) {
      state.calculateFiltersPopup.status = false;
      state.calculateFiltersPopup.helperData = "";
    },
  },
});

export const {
  setIpAddress,
  openCustomerEnteyPopup,
  closeCustomerEnteyPopup,
  addCustomerRegistationDetails,
  addCustomerProfileEditPopup,
  closeCustomerProfileEditPopup,
  openCustomerProfileEditPopup,
  closeCustomerProfileImagePopup,
  openCustomerProfileImagePopup,
  openProductDetailsEstimatePricePopup,
  closeProductDetailsEstimatePricePopup,
  closeLivePricePopup,
  openLivePricePopup,
  addSocialMedia,
  openBarCodeScanner,
  closeBarCodeScanner,
  openProductCodeScanScanner,
  closeProductCodeScanScanner,
  closeEstimateWithoutRatePopup,
  openEstimateWithoutRatePopup,
  closeEstimateWithRatePopup,
  openEstimateWithRatePopup,
  closeProductEstimatePricePopup,
  openProductEstimatePricePopup,
  openSocialProductEstimatePricePopup,
  closeSocialProductEstimatePricePopup,
  openDesignBankProductEstimatePricePopup,
  closeDesignBankProductEstimatePricePopup,
  openBestSellerProductEstimatePricePopup,
  closeBestSellerProductEstimatePricePopup,
  openBestSellerProductDetailsEstimatePricePopup,
  closeBestSellerProductDetailsEstimatePricePopup,
  openSocialMediaProductDetailsEstimatePricePopup,
  closeSocialMediaProductDetailsEstimatePricePopup,
  openWipProductEstimatePricePopup,
  closeWipProductEstimatePricePopup,
  closePricePopup,
  openPricePopup,
  closePrintPopup,
  openPrintPopup,
  setMakingChargesStore,
  setPurityValueStore,
  closeProductTypePopup,
  openProductTypePopup,
  openBestSellerLocationPopup,
  closeBestSellerLocationPopup,
  openIpAddressPopup,
  closeIpAddressPopup,
  closeCategoryPopup,
  closeClassificationPopup,
  closeLocationPopup,
  openCategoryPopup,
  openClassificationPopup,
  openLocationPopup,
  closeChangePasswordPopup,
  setImagesListCarousel,
  openChangePasswordPopup,
  //bulk upload
  closeUserPopup,
  openUserPopup,
  openUploadPopup,
  closeUploadPopup,

  closeUploadBestSellerPopup,
  openUploadBestSellerPopup,

  closeUploadWipPopup,
  openUploadWipPopup,

  closeUploadDesignBankPopup,
  openUploadDesignBankPopup,

  closeUploadSocialMediaPopup,
  openUploadSocialMediaPopup,
  openSynergicsUserCredPopup,
  closeSynergicsUserCredPopup,
  openSynergicsFinanceFilePopup,
  closeSynergicsFinanceFilePopup,
  openGoldPricePopup,
  closeGoldPricePopup,
  openGoldPriceEditPopup,
  closeGoldPriceEditPopup,
  openEditUserPopup,
  closeEditUserPopup,
  closeSelectedImagesPopup,
  openSelectedImagesPopup,
  closeImagesListCarouselPopup,
  openImagesListCarouselPopup,
  setCarouselIndex,
  closeCategoryTypePopup,
  openCategoryTypePopup,
  closeAddIpAddressPopup,
  openAddIpAddressPopup,
  closeEditLocationPopup,
  openEditLocationPopup,
  closeDiscountPopup,
  openDiscountPopup,
  closeCalculateFiltersPopup,
  openCalculateFiltersPopup,
} = customerCheckIn.actions;

export default customerCheckIn.reducer;
