import { createSlice } from "@reduxjs/toolkit";

const initial = {
  productFilter: {
    category: "",
    productType: "",
  },

  popupImages: [],
  selectdPopupImage: null,
  selectedItems: [],
  currentPage: 1,
  pageOffset: 0,
  next: false,
  pervious: false,
  bulkUploadCount: false,
  filterImages: [],
  carouselPagination: 0,
};

// console.log("ppoppp", initial?.popupImages);

const productFilterSlice = createSlice({
  name: `productFilter`,
  initialState: initial,
  reducers: {
    setProductFilters(state, action) {
      state.productFilter.category = action.payload.category;
      state.productFilter.productType = action.payload.productType;
    },

    setSelectedPopupImage(state, action) {
      state.selectdPopupImage = action.payload;
    },

    addpopupImages(state, action) {
      state.popupImages = state.popupImages.concat(action.payload);
    },

    resetPopupImages(state, action) {
      state.popupImages = [];
    },

    setSelectedItems(state, action) {
      state.selectedItems = action.payload;
    },
    clearSelectedItems(state, action) {
      state.selectedItems = [];
    },

    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },

    setNext(state, action) {
      console.log("setNext", action);
      state.next = action.payload;
    },

    setPervious(state, action) {
      state.pervious = action.payload;
    },

    setPageOffset(state, action) {
      state.pageOffset = action.payload;
    },
    setBulkUploadCount(state, action) {
      state.bulkUploadCount = action.payload;
    },
    setFilteredImages(state, action) {
      state.filterImages = action.payload;
    },

    setCarouselPagination(state, action) {
      state.carouselPagination = action.payload;
    },
  },
});

export const {
  setProductFilters,
  addpopupImages,
  setSelectedPopupImage,
  resetPopupImages,
  clearSelectedItems,
  setSelectedItems,
  setCurrentPage,
  setPageOffset,
  setBulkUploadCount,
  setFilteredImages,
  setCarouselPagination,
  setNext,
  setPervious,
} = productFilterSlice.actions;

export default productFilterSlice.reducer;
