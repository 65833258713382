import { createSlice } from "@reduxjs/toolkit";

//bs as BestSeller
const bSitem = localStorage.getItem("bSfilters");
const bSminPrice = localStorage.getItem("bSminPrice");
const bSmaxPrice = localStorage.getItem("bSmaxPrice");
const bSminNetWeight = localStorage.getItem("bSminWeight");
const bSmaxNetWeight = localStorage.getItem("bSmaxWeight");
const bSminDiamondWeight = localStorage.getItem("bSminDiamondWeight");
const bSmaxDiamondWeight = localStorage.getItem("bSmaxDiamondWeight");
const bSordering = localStorage.getItem("bSordering");
const bSsearch = localStorage.getItem("bSsearch");

//sM as SocialMedia
const sMitem = localStorage.getItem("sMfilters");
const sMminPrice = localStorage.getItem("sMminPrice");
const sMmaxPrice = localStorage.getItem("sMmaxPrice");
const sMminNetWeight = localStorage.getItem("sMminWeight");
const sMmaxNetWeight = localStorage.getItem("sMmaxWeight");
const sMminDiamondWeight = localStorage.getItem("sMminDiamondWeight");
const sMmaxDiamondWeight = localStorage.getItem("sMmaxDiamondWeight");
const sMordering = localStorage.getItem("sMordering");
const sMsearch = localStorage.getItem("sMsearch");

const initialState = {
  bSfilters: bSitem ? JSON.parse(bSitem) : [],
  bSminPrice: bSminPrice ? JSON.parse(bSminPrice) : "",
  bSmaxPrice: bSmaxPrice ? JSON.parse(bSmaxPrice) : "",
  bSminWeight: bSminNetWeight ? JSON.parse(bSminNetWeight) : "",
  bSmaxWeight: bSmaxNetWeight ? JSON.parse(bSmaxNetWeight) : "",
  bSminDiamondWeight: bSminDiamondWeight ? JSON.parse(bSminDiamondWeight) : "",
  bSmaxDiamondWeight: bSmaxDiamondWeight ? JSON.parse(bSmaxDiamondWeight) : "",
  bSordering: bSordering ? JSON.parse(bSordering) : "",
  bSsearch: bSsearch ? bSsearch : "",

  sMfilters: sMitem ? JSON.parse(sMitem) : [],
  sMminPrice: sMminPrice ? JSON.parse(sMminPrice) : "",
  sMmaxPrice: sMmaxPrice ? JSON.parse(sMmaxPrice) : "",
  sMminWeight: sMminNetWeight ? JSON.parse(sMminNetWeight) : "",
  sMmaxWeight: sMmaxNetWeight ? JSON.parse(sMmaxNetWeight) : "",
  sMminDiamondWeight: sMminDiamondWeight ? JSON.parse(sMminDiamondWeight) : "",
  sMmaxDiamondWeight: sMmaxDiamondWeight ? JSON.parse(sMmaxDiamondWeight) : "",
  sMordering: sMordering ? JSON.parse(sMordering) : "",
  sMsearch: sMsearch ? sMsearch : "",
};

const bestSellerSocialMediaViewerSlice = createSlice({
  name: "bestSellerSocialMedia",
  initialState: initialState,
  reducers: {
    setBestSellerSearch(state, action) {
      localStorage.setItem("bSsearch", action.payload);
      console.log("BestSellerSearch", action.payload);
      state.bSsearch = action.payload;
    },

    setSocialMediaSearch(state, action) {
      localStorage.setItem("sMsearch", action.payload);
      console.log("socialMediaSearch", action.payload);
      state.sMsearch = action.payload;
    },

    setBestSellerFilter(state, action) {
      localStorage.setItem("bSfilters", JSON.stringify(action.payload));

      state.bSfilters = action.payload;
    },

    setBestSellerOrdering(state, action) {
      localStorage.setItem("bSordering", JSON.stringify(action.payload));

      state.bSordering = action.payload;
    },

    setSocialMediaOrdering(state, action) {
      localStorage.setItem("sMordering", JSON.stringify(action.payload));

      state.sMordering = action.payload;
    },

    setSocialMediaFilter(state, action) {
      localStorage.setItem("sMfilters", JSON.stringify(action.payload));

      state.sMfilters = action.payload;
    },

    setBestSellerMinPrice(state, action) {
      state.bSminPrice = action.payload;
      localStorage.setItem("bSminPrice", JSON.stringify(action.payload));
    },

    setSocialMediaMinPrice(state, action) {
      state.sMminPrice = action.payload;
      localStorage.setItem("sMminPrice", JSON.stringify(action.payload));
    },

    setBestSellerMaxPrice(state, action) {
      state.bSmaxPrice = action.payload;
      localStorage.setItem("bSmaxPrice", JSON.stringify(action.payload));
    },

    setSocialMediaMaxPrice(state, action) {
      state.sMmaxPrice = action.payload;
      localStorage.setItem("sMmaxPrice", JSON.stringify(action.payload));
    },

    setBestSellerMinWeight(state, action) {
      state.bSminWeight = action.payload;
      localStorage.setItem("bSminWeight", JSON.stringify(action.payload));
    },

    setSocialMediaMinWeight(state, action) {
      state.sMminWeight = action.payload;
      localStorage.setItem("sMminWeight", JSON.stringify(action.payload));
    },

    setBestSellerMaxWeight(state, action) {
      state.bSmaxWeight = action.payload;
      localStorage.setItem("bSmaxWeight", JSON.stringify(action.payload));
    },

    setSocialMediaMaxWeight(state, action) {
      state.sMmaxWeight = action.payload;
      localStorage.setItem("sMmaxWeight", JSON.stringify(action.payload));
    },

    setBestSellerMinDiamondWeight(state, action) {
      state.bSminDiamondWeight = action.payload;
      localStorage.setItem(
        "bSminDiamondWeight",
        JSON.stringify(action.payload)
      );
    },

    setSocialMediaMinDiamondWeight(state, action) {
      state.sMminDiamondWeight = action.payload;
      localStorage.setItem(
        "sMminDiamondWeight",
        JSON.stringify(action.payload)
      );
    },

    setBestSellerMaxDiamondWeight(state, action) {
      state.bSmaxDiamondWeight = action.payload;
      localStorage.setItem(
        "bSmaxDiamondWeight",
        JSON.stringify(action.payload)
      );
    },

    setSocialMediaMaxDiamondWeight(state, action) {
      state.sMmaxDiamondWeight = action.payload;
      localStorage.setItem(
        "sMmaxDiamondWeight",
        JSON.stringify(action.payload)
      );
    },

    clearBestSellerFilters(state, action) {
      state.bSfilters = [];
      state.bSminPrice = "";
      state.bSmaxPrice = "";
      state.bSminWeight = "";
      state.bSmaxWeight = "";
      state.bSminDiamondWeight = "";
      state.bSmaxDiamondWeight = "";
      state.bSordering = "";
      state.bSsearch = "";
      localStorage.setItem("bSfilters", []);
      localStorage.setItem("bSminPrice", "");
      localStorage.setItem("bSmaxPrice", "");
      localStorage.setItem("bSminWeight", "");
      localStorage.setItem("bSmaxWeight", "");
      localStorage.setItem("bSminDiamondWeight", "");
      localStorage.setItem("bSmaxDiamondWeight", "");
      localStorage.setItem("bSordering", "");
      localStorage.setItem("bSsearch", "");
    },

    clearSocialMediaFilters(state, action) {
      state.sMfilters = [];
      state.sMminPrice = "";
      state.sMmaxPrice = "";
      state.sMminWeight = "";
      state.sMmaxWeight = "";
      state.sMminDiamondWeight = "";
      state.sMmaxDiamondWeight = "";
      state.sMordering = "";
      state.sMsearch = "";
      localStorage.setItem("sMfilters", []);
      localStorage.setItem("sMminPrice", "");
      localStorage.setItem("sMmaxPrice", "");
      localStorage.setItem("sMminWeight", "");
      localStorage.setItem("sMmaxWeight", "");
      localStorage.setItem("sMminDiamondWeight", "");
      localStorage.setItem("sMmaxDiamondWeight", "");
      localStorage.setItem("sMordering", "");
      localStorage.setItem("sMsearch", "");
    },
  },
});

export const {
  setBestSellerFilter,
  setBestSellerMaxDiamondWeight,
  setBestSellerMaxPrice,
  setBestSellerMaxWeight,
  setBestSellerMinDiamondWeight,
  setBestSellerMinPrice,
  setBestSellerMinWeight,
  setSocialMediaFilter,
  setSocialMediaMaxDiamondWeight,
  setSocialMediaMaxPrice,
  setSocialMediaMaxWeight,
  setSocialMediaMinDiamondWeight,
  setSocialMediaMinPrice,
  setSocialMediaMinWeight,
  clearBestSellerFilters,
  clearSocialMediaFilters,
  setBestSellerOrdering,
  setSocialMediaOrdering,
  setBestSellerSearch,
  setSocialMediaSearch,
} = bestSellerSocialMediaViewerSlice.actions;

export default bestSellerSocialMediaViewerSlice.reducer;
